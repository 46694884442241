import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GoalResponseData, GoalRequestData } from './goal.model';
import { environment } from 'src/environments/environment.development';

@Injectable({ providedIn: 'root' })
export class GoalService {
  private apiUrl = `${environment.apiUrl}/goal`;

  constructor(private http: HttpClient) {}

  createGoal(data: GoalRequestData): Observable<GoalResponseData> {
    return this.http.post<GoalResponseData>(`${this.apiUrl}`, data);
  }

  updateGoal(id: string, data: any): Observable<GoalResponseData> {
    return this.http.patch<GoalResponseData>(`${this.apiUrl}/${id}`, data);
  }
}
