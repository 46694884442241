import { Injectable } from '@angular/core';
import {
  DuplicateRoleRequest,
  DuplicateRoleResponse,
  ListRoleResponseData,
  RoleInterface,
} from './role.model';
import { Observable } from 'rxjs';
import {
  GetAllRolesResponseData,
  RoleRequestData,
  RoleResponseData,
} from './role.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.development';

@Injectable({ providedIn: 'root' })
export class RoleService {
  private apiUrl = `${environment.apiUrl}/role`;

  constructor(private http: HttpClient) {}

  createRole(data: RoleRequestData): Observable<RoleResponseData> {
    return this.http.post<RoleInterface>(`${this.apiUrl}`, data);
  }

  duplicateRole(data: DuplicateRoleRequest): Observable<DuplicateRoleResponse> {
    return this.http.post<DuplicateRoleResponse>(
      `${this.apiUrl}/duplicate`,
      data
    );
  }

  getRole(id: string): Observable<RoleInterface> {
    return this.http.get<RoleInterface>(`${this.apiUrl}/${id}`);
  }

  getAllRoles(doesntHave?: boolean): Observable<GetAllRolesResponseData> {
    return this.http.get<GetAllRolesResponseData>(
      `${this.apiUrl}${doesntHave ? '?doesnt_have[]=kpis' : ''}`
    );
  }

  getRolesByClient(id: string): Observable<ListRoleResponseData> {
    return this.http.get<ListRoleResponseData>(
      `${this.apiUrl}?filters[client_id]=${id}`
    );
  }

  getRolesDoesntHaveKPI(): Observable<ListRoleResponseData> {
    return this.http.get<ListRoleResponseData>(
      `${this.apiUrl}?doesnt_have[]=kpis`
    );
  }

  searchByName(search: string, id?: string): Observable<ListRoleResponseData> {
    return this.http.get<ListRoleResponseData>(
      `${this.apiUrl}?search=${search}`
    );
  }

  updateRole(id: string, data: any): Observable<RoleResponseData> {
    return this.http.patch<RoleResponseData>(`${this.apiUrl}/${id}`, data);
  }
}
