import { Injectable } from '@angular/core';
import {
  KpiResponseData,
  KpiRequestData,
  GetKpiResponseData,
} from './kpi.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.development';

@Injectable({ providedIn: 'root' })
export class KPIService {
  private apiUrl = `${environment.apiUrl}/kpi`;

  constructor(private http: HttpClient) {}

  createKPI(data: KpiRequestData): Observable<KpiResponseData> {
    return this.http.post<KpiResponseData>(`${this.apiUrl}`, data);
  }

  readKPI(id: string): Observable<GetKpiResponseData> {
    return this.http.get<GetKpiResponseData>(`${this.apiUrl}/${id}`);
  }

  updateKPI(id: string, data: any): Observable<KpiResponseData> {
    return this.http.patch<KpiResponseData>(`${this.apiUrl}/${id}`, data);
  }
}
